




























































import { Component, Vue } from 'vue-property-decorator'
import { getGoodsDetail, checkBuyLimit } from '@/pages/videoGame/api'
import { getAppLanguage } from '@/common/utils'
@Component
export default class extends Vue {
  goodsInfo = {
    id: '',
    goodsName: '',
    goodsPic: '',
    goodsDetail: '',
    exchangeLevel: '',
    useIntegral: '',
    exchangeStatus: '', //是否满足兑换 1可以兑换 2vip等级不足 3积分不足
    exchangeLevelName: '',
    checkLimit: '', //是否限购1限购 0不限购
    round: 0, //	周期0不限制 1每天 2每周 3每月
    limitBuyTips: '', //  限购提示
    limitBuyLabel: '' // 限购标签
  }
  language = getAppLanguage()
  isInit = false
  async getDetail() {
    const params = {
      id: this.$route.query.id as string
    }
    const { data } = await getGoodsDetail(params)
    this.goodsInfo = data
    this.isInit = true
  }

  async goConfirm() {
    const params = {
      goodsId: this.$route.query.id as string
    }
    const { data } = await checkBuyLimit(params)
    if (data.result == 0) {
      this.$router.push({
        name: 'goodsConfirm',
        query: {
          goodsId: this.goodsInfo.id
        }
      })
    }
  }
  created() {
    this.getDetail()
  }
}
